
import store from "@/modules/adaptedStorage";
import { alertCircleOutline, closeCircleOutline } from 'ionicons/icons';
import ud from "@/modules/cyanUserData";
import { municipios } from "@/modules/cyanRegions";

import { defineComponent } from "vue";
import {
  IonPage,
  IonTextarea,
  IonButton,
  IonIcon,
  modalController
} from "@ionic/vue";
import CyanPageWrapper from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import ModalSelectorTipoIncidencia from "./ModalSelectorTipoIncidencia.vue";

export default defineComponent({
  name: "ReportarIncidenciaStreamlined",
  components: {
    IonTextarea,
    IonButton,
    IonPage,
    IonIcon,
    CyanPageWrapper,
  },
  setup() {
    return {
      alertCircleOutline,closeCircleOutline
    }
  },
  data() {
    return {
      tipoIncidencia: '',
      comentarios: ''
    };
  },
  computed: {

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    tiposIncidencia(): any {
      return ud('tiposIncidencia', {'0':'Error'});
    },
  },
  methods: {
    async abrirModal() {
      const _t = this as any;
      const modal = await modalController.create({
        component: ModalSelectorTipoIncidencia,
      });
      modal.onWillDismiss().then(function(d) {
        if (typeof _t.tiposIncidencia[(d.data as any)??'0'] !== 'undefined')
          _t.tipoIncidencia = d.data;
      });
      return modal.present();
    },

    cerrarModal: modalController.dismiss,
    enviarIncidencia: async function() {
      await cyanRequest('jrsl/addIncidencia', {
          needsAuth: true,
          isPost: true,
          isModal: true,
          retries: 3,
          params: {
            'tipo_incidencia': this.tipoIncidencia,
            'descripcion': this.comentarios
          }
      }).then((d) => {
        if (d.ok) {
          store.commit('patchUserData', d);
          modalController.dismiss();
        } 
      });
    }
  },
});
