
import { chevronBackCircleOutline, chevronForwardCircleOutline, closeCircleOutline } from 'ionicons/icons';
import ud from "@/modules/cyanUserData";
import { municipios } from "@/modules/cyanRegions";

import { defineComponent } from "vue";
import {
  IonPage,
  IonLabel,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  modalController
} from "@ionic/vue";

export default defineComponent({
  name: "ReportarIncidenciaStreamlined",
  components: {
    IonButton,
    IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
    IonIcon,
  },
  setup() {
    return {
      closeCircleOutline, chevronBackCircleOutline, chevronForwardCircleOutline
    }
  },
  data() {
    return {
      idx: 0
    };
  },
  computed: {

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    tiposIncidencia(): any {
      return ud('tiposIncidencia', {'0':'Error'});
    },
  },
  methods: {
    cerrarModal: modalController.dismiss,
  },
  props: ['videos']
});
