<template>
  <ion-page>
    <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col>
        <div class="banner nivel1">Elija tipo de incidencia</div>
      </ion-col>
    </ion-row>

  

    <ion-row v-for="(indices,epigrafe) in incidenciasIndexadas" :key="epigrafe+'hdr'">
      <ion-col>
        <div class="banner nivel2 epigrafe" @click="epigrafeChanger( epigrafeActual == epigrafe ? '' : epigrafe)"> <div class="nombre">{{ epigrafe }}</div><div class="icon">
          <transition name="cyanfadefast" mode="out-in">
            <ion-icon v-if="epigrafeActual == epigrafe" :icon="caretUpOutline"></ion-icon>
            <ion-icon v-else :icon="caretDownOutline"></ion-icon>
          </transition>
          
          </div></div>
        <transition name="cyanfade" mode="out-in">
        <div class="incidenciasOuter" v-if="epigrafeActual == epigrafe"> 
          <div class="incidencia" v-for="id in indices" :key="id" @click="cerrarModal(id)">{{ tiposIncidencia[id] }}</div>
        </div>
        </transition>
        </ion-col>
    </ion-row>

    
    <ion-row><ion-col>
            <ion-button expand="block" color="danger" @click="cerrarModal">
          <!-- eslint-disable vue/no-deprecated-slot-attribute -->
          <ion-icon slot="start" :icon="closeCircleOutline"></ion-icon>
          CERRAR
        </ion-button>
</ion-col></ion-row>

    </ion-grid>

  </ion-page>
</template>

<script lang="ts">
import { closeCircleOutline, caretUpOutline, caretDownOutline } from 'ionicons/icons';
import ud from "@/modules/cyanUserData";
import { municipios } from "@/modules/cyanRegions";

import { defineComponent } from "vue";
import {
  IonPage,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  modalController
} from "@ionic/vue";

export default defineComponent({
  name: "ModalSelectorTipoIncidencia",
  components: {
    IonButton,
    IonPage,
  IonGrid,
  IonRow,
  IonCol,
    IonIcon
  },
  setup() {
    return {
      closeCircleOutline, caretUpOutline, caretDownOutline
    }
  },
  data() {
    return {
      epigrafeActual: ''
    };
  },
  computed: {

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    tiposIncidencia(): any {
      return ud('tiposIncidencia', {'0':'Error'});
    },
    epigrafesIncidencias(): any {
      return ud('epigrafesIncidencias', {});
    },
    incidenciasIndexadas(): any {
      const r = {} as any;

      let epigrafeActual = '';

      for(const i in this.tiposIncidencia) {
        if (this.epigrafesIncidencias[i]) {
          epigrafeActual = this.epigrafesIncidencias[i];
          if (!r[epigrafeActual])
            r[epigrafeActual] = [];
        }
          r[epigrafeActual].push(i);
      }

      return r;


    }
  },
  methods: {
    cerrarModal: function(x?: any) { modalController.dismiss(x); },
    epigrafeChanger(e: any) {
      if (this.epigrafeActual == '') {
        this.epigrafeActual = e;
        return;
      }
      this.epigrafeActual = '';

      if (e != '') {
        const t = this as any;
        new Promise(function(resolve) { setTimeout(resolve,500);}).then(function() {t.epigrafeActual = e});
      }
    }
  },
});
</script>

<style scoped>
.ion-page {
  height: 100%;
  overflow: auto;
  background: #fdfeff;
  border: 1px solid #333;
}
.incidencia {
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
  background-color: #c5e9f7;
  padding: 15px;
  border: 1px solid #444;
}


.banner.nivel2 {
  padding: 0.5em 0.2em;
}

.epigrafe {
  display: flex;
}

.epigrafe .nombre {
  flex: 1 1 auto;
  text-align: left;
}

.epigrafe .icon {
  flex: 0 0 auto;
}

ion-grid {
  width: 100%;
  color: black;
}
</style>